import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Image from '../components/image';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="title">404: Not found</h1>
    <hr />
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <p>
      You just hit a link that doesn't exist...{' '}
      <span role="img" aria-label="thinking-face">
        🤔
      </span>
    </p>
  </Layout>
);

export default NotFoundPage;
